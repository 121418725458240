.hole-in-one-wrap {

    $wrap-enter-animation: hole-in-one-wrapper-enter 0.5s ease-in-out forwards;
    $wrap-enter-delay: 0s;

    $content-enter-animation: hole-in-one-content-enter 0.5s ease-in-out forwards;
    $content-enter-delay: 1s;

    $text-enter-animation: hole-in-one-text-enter 0.5s ease-in-out forwards;
    $text-enter-delay: 2s;

    $wrap-leave-animation: hole-in-one-wrapper-leave 0.5s ease-in-out forwards;
    $wrap-leave-delay: 3s;


    background: rgba($color: #000000, $alpha: 0.6);
    position: fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index: 9999;
    
    animation:  $wrap-enter-animation;
    animation-delay: $wrap-enter-delay;
   
    overflow: hidden;
    color:#fff;
    .hole-in-one-content {
        width:150vw;
        height:25vh;
        background-size:100%;
        background-position: center center;
        box-shadow: 0px 0px 10px #000;
        position: absolute;
        top:50%;
        display:flex;
        left:-20vw; 
        overflow: hidden;
        width:0;
        animation: $content-enter-animation;
        animation-delay: $content-enter-delay;

       transform: rotate(-10deg) translateY(-50%);
        .hio-text {
            flex:1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            opacity: 0;
            color:#feff8b;
            font-size:3rem;
            
            animation: $text-enter-animation;
            animation-delay: $text-enter-delay;
            
            .second-line {
                margin-top:-2rem;
                font-size:5rem;
            }
        }
    }
}
@keyframes hole-in-one-wrapper-enter {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
@keyframes hole-in-one-content-enter {
    0% {
        width:0;
    }
    100% {
        width:150vw;
    }
}
@keyframes hole-in-one-text-enter {

    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@keyframes hole-in-one-wrapper-leave {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}