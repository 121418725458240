$background-transition-duration: 1s;
$background-total-duration: 3s;
$text-duration: 2s;


@keyframes background-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.7;
    }
}

@keyframes background-fade-out {
    0% {
        opacity: 0.7;
    }
    100% {
        opacity: 0;
    }
}

@keyframes text-wrap-animation {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    30% {
        opacity: 1;
        transform: translateY(0); 
    }
    70% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

.large-transition-wrap {
    position: fixed;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 3rem;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    pointer-events: none;
    
    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0;
       
    }

 
    &.run_animation {
        pointer-events: all;
        .background {
            animation: background-fade-in $background-transition-duration ease-in forwards, background-fade-out $background-transition-duration ease-out $background-total-duration forwards;
        }
        .text-wrap {
            animation: text-wrap-animation $text-duration ease-in-out 1s forwards;
        }
    }

    .text-wrap {
        position: fixed;
        z-index: 2;
        opacity: 0;
        transform: translateY(200%);
       // animation: text-wrap-animation $text-duration ease-in-out 1s forwards;

        .text {
            display:flex;
            flex-direction:column;
            justify-content: center;
            align-items: center;
            
            .heading {
                display: flex;
                align-items: center;
                .heading_text {
                    padding:0px 10px;
                    span {
                        font-size:3rem;
                        padding-left:5px;
                    }
                }
            }
            .desc {
                font-size:1rem;
            }
        }
        .text__goodluck {
            font-size:2rem;
        }

        .line {
            width: 15vw;
            height: 2px;
            background: #fff;
        }
    }
}

