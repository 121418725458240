.asset-loader {
    position: fixed;
    left:0;
    top:0;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width:100%;
    box-sizing: border-box;

    font-family: Arial, Helvetica, sans-serif;
    color:#fff;
    background:#000;
    z-index: 99999;
    .cool-background-canvas {
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
       
   
        z-index: -1;
        .cool-text {
            position: absolute;
            bottom:0;
    
        }
    }
    .progress-bar{
        width: 100%;
        height: 50px;
        box-sizing: border-box;
        border: 5px solid rgba($color: #fff, $alpha: 1);
        .inner {
            width: 0%;
            height: 100%;
            max-width: 100%;
            background: rgba($color: #fff, $alpha: 1);
        }
    }
    .top-spot {
        flex:1;
    }
    .middle-spot {
        flex:1;
   
    }
    .text {
        margin-bottom:20px;
    
        .inner {
            h1 {
                margin:0;
                font-size:1.5rem;
                font-weight: normal;
                padding:0;

            }
            small {
                font-size:1rem;
            }
        }
    }
    .footer-spot {
        padding-bottom:5vh;
        font-size:1rem;
    }
    .start-button-wrap {
        display: flex;
        .start-button {
            padding:5vw 10vw;
            background:#336cf1;
            color:#0b1731;
            font-weight: bold;
        }
    }
}