.geofence-container {
    .geofence-wrap {
        position: fixed;
        left: 0;
        font-family: Arial, Helvetica, sans-serif;
        top: 0;
        width: 100%;
        height: 100%;
        background: black;
        color: #fff;
        display: flex;
        flex-direction: column;
        .geofence-header {
            padding-top: 4vh;
            text-align: center;
            padding-bottom: 3vh;

            .geofence-logo {
                max-width: 200px;
                display: inline-block;
                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .geofence-body {
            flex: 1;

            position: relative;
            display: flex;
            
            padding-bottom: 5vh;
            font-size:1.2rem;

            .geofence-body-inner {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                text-align: center;
                padding:5vw;
                display: flex;
                align-items: center;

                overflow-y: scroll;
                flex-direction: column;

                .geo-spacer {
                    padding-top:10vh;
                }
                a {
                    color: #336cf1;
                    text-decoration: none;
                }

                .geofence-steps {
                    display: flex;
                    flex-direction: column;
                    .geofence-step {
                        display: flex;
                        margin-bottom:5vh;
                        .geofence-step-number {
                          span {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                           
                            width:12vw;
                            height:12vw;
                            border-radius: 800px;
                            background:#333;
                          }
                        }
                        .geofence-step-text {
                            
                        }
                    }
                }
            }
        }
        .geofence-footer {
            padding:5vw;
            max-height: 10vh;
            display: flex;
            .enable-location-button {
                flex: 1;
                background: #336cf1;
                color: #fff;
                font-size: 1.3rem;
                display: flex;
                justify-content: center;
                align-items: center;
             
                border:0;
                border-radius: 10px;
                padding: 5vw;
                text-align: center;
            }
        }
    }
}
