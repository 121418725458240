.snap-gallery-item {
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background:rgba($color: #000000, $alpha: 0.7);
    z-index:999999;

    .snap-loader {
        position: fixed;
        left:0;
        top:0;
        width:100%;
        height:100%;
        z-index:999999;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width:30vw;
            fill:white;
            scale:1;
            animation: spinAndPulse 4s linear infinite;
        }
        @keyframes spinAndPulse {
            0% {
                scale:1;
                transform: rotate(0deg);
            }
            50% {
                scale:0.5;
                transform: rotate(180deg);
            }
            100% {
                scale:1;
                transform: rotate(360deg);
            }
        }
    }

    .snap-picture{
        position: fixed;
        left:0;
        top:0;
        width:100%;
        height:100%;
        display: flex;
        justify-content: center;

        align-items: center;
        .pic {
            position: relative;
            .close {
                position: absolute;
                top:0;
                right:0;
                z-index: 999;
                top:-5vw;
                right:-5vw;
                svg {
                    width:15vw;
                    fill: #fff;
                    path {
                        fill: #fff;

                    }
                    height:15vw;
                }
            }
            img {
    
                max-width:90vw;
                max-height:90vh;
            }
        }
        
    }
}