.welcome-template {
    position:fixed;
    left:0;
    top:0;
    width:100%;
     height: 100svh;

    display:flex;
    flex-direction: column;
    align-items:center;
    background-color: #efefef;
    background-size:cover;
    background-position: center center;
    box-sizing: border-box;
    color:#fff;
    .top-section {
        display: flex;
        width:100%;
        justify-content: center;
        align-items: center;
        padding:0 5vw;
         padding-bottom:2vh;
        position: relative;
        z-index: 10;
        max-height:30vh;
        .logo {
          
            box-sizing: border-box;
            max-height:30vh;
            padding:0px 5vw;
            padding-top:5vh;
            img {
                max-height:100%;
                max-width:100%;
            }
            
        }
    }
    .middle-section {
        display: flex;
        width:100%;
        position: relative;
        z-index: 10;
        flex:1;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .bottom-section {
        flex:1;
        max-height:10vh;
        display:flex;
        position: relative;
        z-index: 10;
        width:100%;
        padding-bottom:5vh;
        align-items: end;

 
        // .flags {
        //     display: flex;
        //     width:100%;
        //     justify-content: space-between;
        //     padding:0 5vw;
        //     gap: 2vw;
        //     .flag {
        //         opacity: 0.5;
        //         img {
        //             width:100%;
        //             display: block;
        //         }
        //         &.active-flag {
        //             opacity: 1;
        //             box-shadow: 0px 0px 5px 5px rgba($color: #000000, $alpha: 0.6);
        //         }
        //     }
        // }

        .bottom-navi {
            display:flex;
            width:100%;
   
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding:0 5vw;
            .bottom-navi-left {
                display: flex;
                justify-content: center;
                position:relative;
                align-items: center;
                .language-button-wrap {
                    width:8vh;
                    height:8vh;
                  
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    background-size:100% 100%;
                    .current-flag {
                        width:100%;
                        height:100%;
                        background-size:60%;
                        background-position: center center;
                        background-repeat: no-repeat;
                   
                        top:0;
                      
                        left:0;
                        position: absolute;
                    }
                .language-button {
                    position: relative;
                    text-align: center;
                  
                    .language-list-wrap {
                        position: absolute;
                        left:5vw;
                        top:-6vh;
                        padding:3vw;
                        background:rgba($color: #000000, $alpha: 0.5);
                        display: flex;
                        width:80vw;
                        z-index: 999;
                        justify-content: center;
                        align-items: center;
                        .flags {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 3vw;
                            img {
                                display: block;
                            }
                            .active-flag {
                                display:none;
                            }
                        }
                    }
                    .current-language-flag {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-bottom:0.4vh;
                        position: relative;
                       
                        img {
                            width:50%;
                            display: block;
                            border:2px solid rgba($color: #000000, $alpha: 0.5);
                        }
                    }
                    span {
                        text-shadow: 0px 0px 5px rgba($color: #000000, $alpha: 1);
                   
                        
                    }
                }
            }
            }
            .bottom-navi-center {
                flex:1;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .powered-by-text {
                    display: flex;
                    flex-direction: column;
                    text-shadow: 0px 0px 5px rgba($color: #000000, $alpha: 1);
                    font-size:01rem;
                    line-height: 1rem;
                }
                .powered-by-logo {
                    flex:1;
                    width:100%;
                    
                    display: flex;
                    justify-content: center;
                    padding-top:5px;
                    align-items: end;
           
                    svg {
                        width:100%;
                        filter: drop-shadow(0px 0px 5px rgba($color: #000000, $alpha: 1));
                      max-width:100px;
                       
                    }
                }
            }
            .bottom-navi-right {
                display: flex;
                justify-content: center;
                align-items: center;
                .sign-in-button-wrap {
                    width:8vh;
                    height:8vh;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    background-size:100% 100%;

                  

                    .sign-in-button {
                        position: relative;
                        text-align: center;
                        span {
                            text-shadow: 0px 0px 5px rgba($color: #000000, $alpha: 1);
                        }
                        
                    }
                }
            }
        }

    }
}

