.unfinished-game {
    .holes-container {
        text-align: center;
        .hole {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
            font-size: 2rem;
            position: relative;
            width: 13vw;
            flex: 1;
            margin: 1vw 1vw;
            opacity: 0.5;
            aspect-ratio: 1/1;
            background-size: 100% 100%;

            span {
                position: relative;
                top: -2px;
            }
            .par {
                position: absolute;
                font-size: 0.8rem;
                bottom: -5px;
                b {
                    font-size: 1rem;
                }
            }
            &.active-hole {
                color: #fff !important;
            }
            &:first-child {
                margin-left: 20px;
            }
            &:last-child {
            }
            .hole-status {
                position: absolute;
                width: 20px;
                height: 20px;
                background-size: 17px 17px;
                background-position: center center;
                background-repeat: no-repeat;
                top: -5px;
                font-size: 0.8rem;
                display: flex;
                justify-content: center;
                align-items: center;
                right: -5px;
                border-radius: 20px;
            }
        }
        .invalid {
            opacity: 1;
        }
    }
    .finalize-button {
        margin-top:5vh;
    }
}
