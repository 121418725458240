.winner-text {
    max-width: 80vw;

    margin-top: 5vh;
    text-align: center;
    font-size: 1.2rem;
    line-height: 2rem;
}
.winner-content {
    display: flex;

    .winner-left {
        align-self: center;
        .ball-color-container {
            display: flex;
            justify-content: center;
            align-items: center;
            .ball-color {
                margin-left: 20px;
                margin-right: 8vw;
                aspect-ratio: 1/1;
                height: 5vh;
                //background: temp-url('player-ball-frame.png');
                background-color: black;
                background-size: 100% 100%;
                transform: rotate(45deg);
            }
        }

        .avatar-wrap {
                   
            padding-left:20px;
            padding-right:20px;
            display: flex;
            justify-content: center;
            align-items: center;
            .avatar {
                position: relative;
                width:20vw;
                height:20vw;
                background-size: 400% 400%;
              

                box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 1.0);
           
                .avatar-frame {
                    position: absolute;
                    width:100%;
                    height:100%;
                    background-size:100% 100%;
                    background-repeat: no-repeat;
                }
            }
        }
    }
    .winner-right {
        padding-right: 5vw;
        box-sizing: border-box;
        width:100%;
        .title {
            display: flex;
            flex-direction: column;
            justify-self: center;
            letter-spacing: 0.1rem;
            font-size: 1.5rem;
            img {
                margin-bottom: 5px;
                width: 40px;
            }
            span {
                display: block;
            }
        }
        .name {
            font-size: 4rem;
        }
        .score {
            text-align: right;
        }
    }
}