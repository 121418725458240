.signup-reminder-wrap {
    min-height:50vh;

    position: relative;
    background-size:cover;
    display: flex;
    justify-content: center;
    padding:5vh 0;
    align-items: center;
    flex-direction: column;
    background-position: center center;
    transition: all 0.1s ease-in-out;
    .signup-fade {
        
        height:10vh;
        position: absolute;
        left:0;
        top:0px;
        width:100%;
        z-index: 99;
        background-image: linear-gradient(to bottom, rgba(56,45,45,1), rgba(255,0,0,0));
    }
    .signup-dark {
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background:rgba($color: #000000, $alpha: 0.5);
    }
    .signup-content {
        position: relative;
        z-index: 999;
        padding:0 5vw;
        text-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.3);
        .signup-title {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size:2.5rem;
            margin-bottom:2vh;
        }
        .signup-text {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size:1.2rem;
            margin-bottom:2vh;
        }
        .signup-button {
            margin:0 5vw;
            background:#336cf1;
            color:#fff;
            font-size:1.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            text-align: center;
            height:8vh;
            flex:1;
        }
        .signup-ppg-logo {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size:1.2rem;
            margin-top:3vh;
            svg {
                width:100%;
                max-height:20px;
            }
        
        }
    }
    .player-chooser-wrap {
        display: block;

        text-align: center;
        margin-bottom: 2vh;
        .player-choose-indi {
            display: inline-block;
            margin:10px;
            margin-top:0;
            margin-bottom:0;
            width:18vw;
            aspect-ratio: 1/1;
            transition: all 0.1s ease-in-out;
        
            position: relative;
            border-radius: 1000px;
            border: 2px solid #000;
            overflow: hidden;
            /* black and white filter */
            filter: grayscale(100%);
            .choose-avatar {
                width:100%;
                height:100%;
                background-size:400% 400%;
            }
            
            
        }
        .active-chosen {
            filter: grayscale(0%);
        }
    }
    .chosen-player-name {
        display: flex;
        margin:2vh 0;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .label {
            font-size:1rem;
        }
        .username {
            font-size:2.8rem;
            margin:1vh 0;
        }
        .small {
            text-align: center;
            font-size:0.7rem;
          
        }
    }
}