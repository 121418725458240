.feedback-container {
    padding-bottom: 3vh;
    text-align: center;
    position: relative;
    margin-top:10vh;
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 2rem;
    }
    .description {
        margin: 10px 0;
        padding: 0 5vw;
    }
    .stars-wrap {
        .star {
            width: 10vw;
            height: 10vw;
            margin: 0 2vw;
            display: inline-block;
            svg {
                width:10vw;
                height:10vw;
                fill: #000;
                path {
                    fill: #000;
                }
            }
            &.selected {
                svg {
                    path {
                        fill: #FFC107;
                    }
                }
            }
        }
    }
    .comments-wrap {
        padding: 2vh 5vw;
        box-sizing: border-box;
        textarea {
            width: 100%;
            min-height: 100px;
            border-radius: 5px;
            border: none;
            &::placeholder {
                color: #fff;
            }
            font-size: 1rem;
            padding: 2vw;
            background:rgba($color: #000000, $alpha: 0.5);
            box-sizing: border-box;
            outline: none;
            color:#fff;
            resize: vertical;
        }
    }
    .submit-wrap {
        position: relative;
        padding: 0 5vw;
        span {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            pointer-events: none;
            align-items: center;
            justify-content: center;
            font-size: 7vw;
        }
        img {
            width: 100%;
        }
    }
    .feedback-saving {
        position: absolute;
        left: 0;
        top:0;
        width:100%;
        height:100%;
        box-sizing: border-box;
        flex-direction: column;
        display: flex;
        justify-content: center;
        background:rgba($color: #000000, $alpha: 0.8);
        z-index: 999;
        align-items: center;
        .saving-text {
            color:#fff;
            font-size: 2rem;
      
        }
        .saving-spinner{
            svg {
                width:20vw;
                height:20vw;
                fill:#fff;
                animation: spinAndPulse 2s linear infinite;
            }
        }
        .thank-you {
            color:#fff;
            font-size: 2rem;
           
        }
    }
}
