.best-worst-hole-wrap {

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0 auto;

    .best-worst-main {
        flex: 1;

        width: 100%;
        display: flex;
        align-items: flex-start;
        box-sizing: border-box;
        gap: 5vw;
        .inside-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .title {
                text-align: center;
                font-size: 1.7rem;
            }
            .large-number {
                font-size: 6rem;
                text-align: center;
                line-height: 6rem;
            }
            .par {
                font-size: 1.5rem;
                text-align: center;
            }
            .group {
                font-size: 1.5rem;
                text-align: center;
            }
            .best {
                color: #91c689;
            }
            .worst {
                color: #eb4141;
            }
        }

        .left {
            flex: 1;
        }
        .right {
            flex: 1;
        }
    }

    .best-worst-chooser-wrap {
        padding:0 10vw;

        align-items: center;
        flex:1;
        width:100%;
        box-sizing: border-box;
 
        margin-bottom:4vh;

        .best-worst-dropdown {
            margin-top:2vh;
            position: relative;
            .selected-option {
                display: flex;
                background:#211b1b;
                align-items: center;
               
                padding:10px 20px;
                width:100%;
            
                box-sizing: border-box;
                .display {
                    .icon {

                    }
                    .text {

                    }
                }
                .close {
                    flex:1;
                    display: flex;
                    
                    justify-content: flex-end;
                    height:5vh;
                    .icon {
                        transition: all 0.2s ease-in-out;
                        aspect-ratio: 1/1;
                      
                        background-size:100% 100%;
                    }
                }
            }
            .list {
                display: none;
                position: absolute;
                background:#3d3232;
                box-shadow: 0px 0px 10px rgba(#000, 0.5);
                width: 100%;
                z-index: 999;
                left:0;
                .item {
                    padding: 20px;
                    border-bottom:3px solid #342b2b;
                    .icon {

                    }
                    .text {

                    }
                }
            }
        }
    }
}


/*
.performance-dropdown {
                margin-top:2vh;
                position: relative;
                .selected-option {
                    display: flex;
                    background:#211b1b;
                    align-items: center;
                   
                    padding:10px 20px;
                    width:100%;
                
                    box-sizing: border-box;
                    .display {
                        .icon {

                        }
                        .text {

                        }
                    }
                    .close {
                        flex:1;
                        display: flex;
                        
                        justify-content: flex-end;
                        height:5vh;
                        .icon {
                            transition: all 0.2s ease-in-out;
                            aspect-ratio: 1/1;
                          
                            background-size:100% 100%;
                        }
                    }
                }
                .list {
                    display: none;
                    position: absolute;
                    background:#3d3232;
                    box-shadow: 0px 0px 10px rgba(#000, 0.5);
                    width: 100%;
                    z-index: 999;
                    left:0;
                    .item {
                        padding: 20px;
                        border-bottom:3px solid #342b2b;
                        .icon {

                        }
                        .text {

                        }
                    }
                }
            }
*/