$phoneWidth: 800px;
$tablet-win-device-width: 768px;
$desktop-win-device-width: 1024px;
$tablet-max-device-width: 1024px;
.testimonials-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom:10vh;
    background-size:50% auto;
    background-position: center;
    position: relative;

    @media (max-width: $phoneWidth) {
        padding-bottom:5vh;
    }
    @media only screen and (min-device-width: $tablet-win-device-width) and (max-device-width: $tablet-max-device-width) {

        padding-top:10vh;
    }
    .top-fader {
        top:0;
        left:0;
        width:100%;
        height:80px;
        pointer-events: none;
        position: absolute;
        background: -webkit-linear-gradient(-90deg, #0e1927,transparent);
    }
    .bottom-fader {
        bottom:0;
        left:0;
        width:100%;
        height:80px;
        pointer-events: none;
        position: absolute;
        background: -webkit-linear-gradient(90deg, #0e1927,transparent);
    }

    .description {
        text-align: center;
        position: relative;
        z-index: 10;
        margin-bottom:10vh;
        @media (max-width: $phoneWidth) {
            margin-bottom:5vh;
        
        }
        h2 {
            margin: 0;
            font-size: 3.5rem;
            background: -webkit-linear-gradient(360deg, #5fba3f, #336cf1);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            @media (max-width: $phoneWidth) {
                font-size: 2.4rem;
                padding:0 20px;
            
            }
        }
        h3 {
            margin: 0;
            margin-top:2vh;
            font-size: 1.7rem;
            line-height: 2rem;
            @media (max-width: $phoneWidth) {
                margin:0 auto;
                margin-top:2vh;
                font-size: 1.2rem;
                max-width:300px;
               
            }
        }
    }
    .testimonial-carousel-wrap {

        flex:1;
        width:100%;

        .testimonials-carousel {
            position: relative;
            .fader {
                position: absolute;
                pointer-events: none;
                width: 10vw;
                max-width: 250px;
                z-index: 999;
                height: 100%;
            }
            .fader-left {
                left:0;
                top:0;
          
                background: -webkit-linear-gradient(360deg, #0e1927, transparent);
                z-index: 999;
            }
            .fader-right {
                right:0;
                top:0;
          
               
               
                background: -webkit-linear-gradient(180deg, #0e1927, transparent);
                //0e1927
                
            }
        }
       
        .swiper-wrapper {
            align-items: center; // This ensures vertical alignment
          }
        
          .swiper-slide {
            width:50vw;
            padding:20px;
            max-width:500px;
            background:#326bed;
            opacity: 0.2;
            border-radius: 20px;
           
          }
          .swiper-slide-active {
            background:#326bed;
            opacity: 1;
          }
          
          .testimonial-item {
            display: flex; 
            flex-direction: column;
            .desc {
                flex: 1;
                line-height: 2rem;
                font-size:1rem;
                p {
                    margin:0;
                }
                @media (max-width: $phoneWidth) {
                    font-size:0.8rem;
                    line-height: 1.3rem;
                    text-align: center;
                }
            }
            .bottom {
                display: flex;
                padding-top:20px;
                justify-content: center;
                align-items: center;
                @media (max-width: $phoneWidth) {
                    flex-direction: column;
                }
                .person {
                    flex:1;
                    display: flex;
                    align-items: center;
                    .avatar {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background-color:#fff;
                        background-size:cover;
                        margin-right: 20px;
                        @media (max-width: $phoneWidth) {
                            width: 40px;
                            height: 40px;
                            margin-right: 10px;
                        }
                    }
                    .bio {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .name {
                            @media (max-width: $phoneWidth) {
                                font-size:0.8rem;
                            }
                        }
                        .title {
                            font-size:0.8rem;
                            font-style: italic;
                            @media (max-width: $phoneWidth) {
                                font-size:0.6rem;
                            }
                        }
                    }
                }
                .stars {
                    @media (max-width: $phoneWidth) {
                        margin-top:20px;
                    }
                    .star-box {
                       
                        align-items: center;
                        background:#2b5bc9;
                        justify-content: center;
                        
                        padding:2px 10px;
                        border-radius: 20px;
                        .star {
                            display: inline-block;
                            width: 15px;
                            height: 15px;
                            margin-right:5px;
                            opacity: 0.3;
                            &:last-child {
                                margin-right:0;
                            }
                            
                         
                            svg {
                                width: 100%;
                                fill: yellow !important;
                                height: 100%;
                                path {
                                    fill: yellow !important;
                                
                                }
                            }
                            
                        }
                        .active-star {
                            opacity: 1;
                            svg {
                                path {
                                    
                                }
                            }
                        }
                    }
                }
            }
            
          }
    }
    .testimonial-controls {
        margin-top:5vh;
     
        justify-content: center;
        align-items: center;
        display: flex;
        gap: 20px;
        @media (max-width: $phoneWidth) {
            margin-top:2vh;
        }
        .control {
            width:30px;
            cursor: pointer;
            height:30px;
            border-radius: 100%;
           
            display: flex;
            justify-content: center;
            align-items: center;
            border:2px solid #326bed;
            padding:10px;
            transition: all 0.2s ease-in-out;
            font-size:2rem;
            &:hover {
                background:#326bed;
                color:#fff;
            }
        }
    }
}