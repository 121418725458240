.rules-page {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100svh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000;
    background-size: cover;
    background-position: center center;
    box-sizing: border-box;
    color: #fff;
    .rules-body-wrap {
        flex: 1;
        height: 80svh;
        display: block;
        position: relative;
        box-sizing: border-box;
        .rules-body {
            box-sizing: border-box;
            padding: 5vw;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            line-height: 2.2rem;
            font-size: 1.3rem;
            text-transform: lowercase;
            /* Custom scrollbar */
            &::-webkit-scrollbar {
                width: 10px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #111;
                margin-top: 10vw;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
    }
    .rules-footer {
        flex: 1;
        width: 100%;
        display: flex;

        justify-content: center;
        align-items: center;
        .back-button {
            height: 10vh;
            position: relative;
            span {
                position: absolute;
                left: 0;
                top: 0;
                pointer-events: none;
                color: #fff;
                width: 100%;
                font-size: 2.2rem;
                height: 100%;
                display: flex;

                justify-content: center;
                align-items: center;
                z-index: 10;
            }
            img {
                height: 100%;
            }
        }
    }
}

// .rules-page {
//     flex: 1;
//     width:100%;
//     flex-direction: column;
//     max-width: 600px;
//     box-sizing: border-box;
//     display:flex;
//     .rules-body {
//         background-color: rgba($color: #000000, $alpha: 0.7);

//         overflow-y: scroll;
//         display: flex;
//         flex-direction: column;

//         color:#fff;
//         max-height:60vh;
//         text-transform: lowercase;

//         flex-grow: 1;
//         font-size:1.1rem;
//         padding:20px;
//         line-height:2.2rem;
//         box-sizing: border-box;
//         h1 {
//             font-size:2rem;
//             font-weight: bold;
//             margin-bottom:0px;
//         }
//     }
//     .rules-footer {
//         flex:1;
//         display: flex;
//         justify-content: center;
//         position: relative;
//         padding:20px;
//         .back-button {
//             height:10vh;
//             position: relative;
//             span {
//                 position: absolute;
//                 left:0;
//                 top:0;
//                 pointer-events: none;
//                 color:#fff;
//                 width:100%;
//                 font-size:2.2rem;
//                 height:100%;
//                 display: flex;

//                 justify-content: center;
//                 align-items: center;
//                 z-index: 10;
//             }
//             img {

//             }
//         }

//     }
// }
