$phoneWidth: 800px;
$tablet-win-device-width: 768px;
$desktop-win-device-width: 1024px;
$tablet-max-device-width: 1024px;
body {
    // position: relative !important;
    // height: auto;
    // background: #fff;
    // font-family: "Open Sans", Arial, Helvetica, sans-serif;
    // font-size: 18px;
    // width: 100%;
    // min-height: 100vh !important;
    // max-height: none !important;

    /* customize scrollbar color and width */
 
}
// html,
// body {
//     overflow: auto !important;
//     overscroll-behavior-y: auto !important;
// }
$body-bg: #0e1927;

#home-screen {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: $body-bg;
    position: fixed;
    overflow-y: scroll;
    color: #fff;

    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-size: 18px;

    &::-webkit-scrollbar {
        width: 15px;
        background-color: #000;
        border-radius: 10px;
        cursor: pointer;
        border-left: 15px solid #000;
        padding-right: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: -webkit-linear-gradient(-90deg, #3a79d3, #173058);
        box-shadow: 0px 0px 10px #000;
        border-left: 1px solid #0a111b;
        max-height:20px;
        width:50%;
        cursor: pointer;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        border: 1px solid #0a111b;
        background-color: #192536;
    }

    .header {
        z-index: 99;
        position: fixed;

        z-index: 9999;
        left: 0;
        top: 0;
        padding: 2vw;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        font-weight: 100;
        justify-content: space-between;
        align-items: center;
        background: -webkit-linear-gradient(-90deg, #0e1927, transparent);
        padding-bottom:10vh;
        @media (max-width: $phoneWidth) {
            padding-top:30px;
            padding-bottom:5vh;
        }
        .left {
            @media (max-width: $phoneWidth) {
                display: none;
            }
        }
        .right {
            @media (max-width: $phoneWidth) {
                display: none;
            }
        }
        .center {
            display: flex;
            justify-content: center;
            align-items: center;
           
            flex: 1;
            .logo {
                max-width: 200px;
                svg {
                    max-width: 200px;
                    width: 100%;
                    
                    path {
                        fill: -webkit-linear-gradient(360deg, #ffe868, #ba4b45);
                    }
                }
            }
        }
        .navi-item {
            transition: all 0.3s ease-in-out;
        }
        .navi-item:hover {
            color:#3a79d3;
            cursor: pointer;
           
        }
    }

    .hero-wrap {
        min-height: 105svh;

        background-size: auto 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-color:#000;
       
        position: relative;

        .hero-video {
            position: absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            video {
                width:100%;
                height:100%;
                object-fit: cover;
            }
        }
        .hero-phone {
            position: absolute;
            width: 100%;
            left: 0;
            justify-content: center;
            display: flex;
            align-items: center;
            bottom: 0;
            padding: 0 5vw;
            top:20vh;
            box-sizing: border-box;
            z-index: 99;
            img {
                display: block;
                max-height: 100vh
            }
            @media only screen and (min-device-width: $tablet-win-device-width) and (max-device-width: $tablet-max-device-width) {
                top: 0;
            }
            
        }
        .hero-gradient {
            position: absolute;
            left:0;
            width:100%;
            bottom:0;
            height:20vh;
            background-size:cover;
         
        }
    }

    .main-content {
        .content-block {
        }
        .embrace-revolution {
            padding: 5vw;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 40vh;
            padding-bottom: 40vh;
            padding-top:30vh;
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-color: #121f31;
            background-position: center bottom;
            @media (max-width: $phoneWidth) {
                padding-top:20vh;
                padding-bottom:10vh;
            }
            @media only screen and (min-device-width: $tablet-win-device-width) and (max-device-width: $tablet-max-device-width) {
                padding-top:0;
                padding-bottom:10vh;
            }

            h3 {
                margin: 0;
                font-size: 1.7rem;
                line-height: 2rem;
                @media (max-width: $phoneWidth) {
                    font-size: 1.2rem;
                }
            }
            h1 {
                font-size: 5rem;
                margin: 0;
                font-weight: bolder;
                background: -webkit-linear-gradient(360deg, #5fba3f, #336cf1);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin: 2vh 0;
                @media (max-width: $phoneWidth) {
                    font-size: 3rem;
                }
            }
            .desc {
                max-width: 800px;
                line-height: 2.3rem;
                @media (max-width: $phoneWidth) {
                    font-size:  1.0rem;
                }
                p {
                    margin: 0;
                }
            }
        }

        .benefits-wrap {
            margin: 0 auto;
            padding: 0 5vw;
            padding-top: 5vh;
            width: 80vw;
            position: relative;
            max-width: 1100px;
            .benefit {
                display: flex;
                padding-bottom: 10vh;
                position: relative;
                @media (max-width: $phoneWidth) {
                        flex-direction: column;
                        padding-bottom:10vh;
                    }
                &:last-child {
                    //padding-bottom:0;
                }
                .desc {
                    flex: 1;
                    line-height: 2.5rem;
                    
                    padding-right: 5vw;
                    position: relative;
                    @media (max-width: $phoneWidth) {
                        padding-right: 0;
                        width:100%;
                        text-align: center;
                        padding-top:5vh;
                        order: 2;
                    }
                    
                    h2 {
                        margin: 0;
                        font-size: 2rem;
                        background: -webkit-linear-gradient(360deg, #ffe868, #ba4b45);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
               
                    }
                   
                }
               
                .icon {
                    background-color: #121f31;
                    display: flex;
                    @media (max-width: $phoneWidth) {
                        justify-content: center;
                        width:100%;
                        height:20vh;
                    }
                    .icon-item {
                        flex: 1;
                        background-size: cover;
                        width: 20vw;
                        max-width: 300px;
                        border-radius: 20px;
                    }
                }
                &.rightSide {
                    .desc {
                        order: 2;
                        padding-right: 0;
                        padding-left: 5vw;
                        @media (max-width: $phoneWidth) {
                            padding-left: 0;
                        }
                    }
                    .icon {
                        order: 1;
                    }
                }
            }
        }
    }


    .contact-wrap {
        display: flex;
        margin:0 auto;
     
        max-width:1400px;
        margin-top:10vh;
        
        min-height:600px;
        justify-content: center;
        padding:5vh 5vw;
   
        box-sizing: border-box;
        position: relative;



        @media (max-width: $phoneWidth) {
            flex-direction: column;
            padding:5vh 5vw;
            margin-top:0vh;
        }

        @media only screen and (min-device-width: $tablet-win-device-width) and (max-device-width: $tablet-max-device-width) {
            padding-top:0;
        }

        .loading-wrap {
            position: absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            backdrop-filter: blur(6px);
            background-color: rgba($color: #000, $alpha: 0.3);
            align-items: center;

          
            .loading-text {
                font-size: 1.9rem;
            }
        }
        .left-block {
            background:#121f31;
            border-radius: 20px;
            background-size:cover;
            flex:1;
            max-width:400px;
        }
        .right-block {
            flex:1;
            padding-left:5vw;
            @media (max-width: $phoneWidth) {
                padding-left:0;
            }
            h1 {
                margin:0;
                font-size:4rem;
                background: -webkit-linear-gradient(360deg, #5fba3f, #336cf1);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @media (max-width: $phoneWidth) {
                    text-align: center;
                    font-size:2.3rem;
                }
            }
            h2 {
                margin: 0;
                font-size: 1.2rem;
                @media (max-width: $phoneWidth) {
                    text-align: center;
                    margin-top:10px;
                    font-size:1rem;
                }
            }

            .form-wrap {
                display: flex;
                flex-direction: column;
                padding-top:5vh;
                .row {
                    width:100%;
                    display: flex;
                    gap:20px;
                    padding-bottom:20px;
                    @media (max-width: $phoneWidth) {
                        flex-direction: column;
                        gap:0px;
                        padding-bottom:0;
                    }
                   
                    .item {
                        flex:1;
                        @media (max-width: $phoneWidth) {
                            padding-top:5px;
                           
                        }
                        b {
                            font-size:1rem;
                            margin-bottom:10px;
                            display: block;
                            color:#777;
                        }
                        input,textarea {
                            width:100%;
                            padding: 20px 20px;
                            border-radius: 10px;
                            font-family: 'Open Sans', sans-serif;
                            font-size:1.3rem;
                            border: 1px solid #0a111b;
                            background-color: #121f31;
                            color: #fff;
                            outline: none;
                            font-size: 1rem;
                            margin-bottom: 10px;
                            resize: vertical;
                            max-height: 200px;
                            box-sizing: border-box;
                        }
                        .submit-button {
                            background:  #326bed;
           
                            border: 1px solid #0a111b;
                            padding: 20px 20px;
                            border-radius: 10px;
                            font-family: 'Open Sans', sans-serif;
                            font-size:1.3rem;
                            border: 1px solid #0a111b;
              
                            color: #fff;
                            outline: none;
                            font-size: 1rem;
                            margin-bottom: 10px;
                            box-sizing: border-box;
                            cursor: pointer;
                            width:100%;

                            box-sizing: border-box;
                        }
                    }
                }
            }
        }
    }

    .footer-wrap {
        padding: 5vw;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #121f31;
        padding-bottom: 10vh;
        padding-top:35vh;
        background-size: 100% auto;
        background-repeat: no-repeat;
        margin-top:5vh;
   
        background-position: center top;

         @media (max-width: $phoneWidth) {
            padding-top:10vh;
            padding-bottom:10vh;
         }
         @media only screen and (min-device-width: $tablet-win-device-width) and (max-device-width: $tablet-max-device-width) {
            padding-top:5vh;
            padding-bottom:20vh;
        }

        .footer {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @media only screen and (min-device-width: $tablet-win-device-width) and (max-device-width: $tablet-max-device-width) {
                margin-top:10vh;
            }
            .small-logo {
                display: flex;
                justify-content: center;
                align-items: center;
              
                max-width:200px;
                svg {
                    width:100%;
                }
                @media (max-width: $phoneWidth) {
                    margin-bottom:20px;
                }
            }
            .contact-info {
                margin-top:20px;
                display: flex;
                gap: 20px;
                @media (max-width: $phoneWidth) {
                    flex-direction: column;
                }
                .item {
                    a {
                        color:#fff;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

