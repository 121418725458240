.snapper-controls {
    position: fixed;

    bottom: 0;
    overflow: hidden;
    z-index: 30;
    left: 0;
    width: 100%;

    margin: 0 auto;

    .filter-icons {
        display: flex;
        overflow-x: auto;

        white-space: nowrap;
        align-items: center;
        height: 20vh;

        width: 100%;
        padding-left: 50vw;
        padding-right: 50vw;
        scroll-snap-type: x mandatory;
        box-sizing: border-box;
        transition: all 0.2s ease-in-out;
    }

    .filter-icon {
        display: inline-flex;
        flex: 1;
        margin: 0 5vw;
        border-radius: 50%;
        height: 15vw;
        width: 15vw;
        cursor: pointer;

        position: relative;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        scroll-snap-align: center;
    }
    .empty-icon {
        visibility: hidden;
    }
    .filter-image {
        position: absolute;
        width: 100%;
        height: 100%;

        background-size: cover;
        border-radius: 100%;
        transition: all 0.2s ease-in-out;
    }
    .snap-button {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: calc(-6vh);
        margin-top: -6vh;
        height: 12vh;
        width: 12vh;

        outline: none;
        border: 5px solid red;
        background: transparent;

        border-radius: 50%;
    }

    /* Hiding the scrollbar */
    .filter-icons::-webkit-scrollbar {
        display: none;
    }
    .filter-icons {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}
