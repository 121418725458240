
.results-page {
    height: 100vh;
    max-height: -webkit-fill-available;
    // if mobile set height to 100%
    display: flex;
    flex-direction: column;
    background-position: top;

    background-repeat: no-repeat;
    box-sizing: border-box;
    position: relative;
    color: #fff;
    //background-image: url("../../../../public/customers/castle-golf/images/welcome-background.jpg");
    background-size: 100% auto;
    background-color: #382d2d;


    .gradient {
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 20vh;
        background-repeat: auto no-repeat;

        background-size: auto 100%;
    }

    .top-image {
        width: 100%;
        position: absolute;
    }
    .header {
        display: flex;
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        box-sizing: border-box;
        padding: 20px;

        z-index: 19999;
        background-image: linear-gradient(to bottom, rgba(56,45,45,1), rgba(255,0,0,0));
        align-items: center;
        .left {
            flex:1;
            span {
                display: flex;
              
                align-items: center;
                aspect-ratio: 190 /49;
                width:30vw;
                background-size:100% 100%;
            }
        }
        .right {
            display: flex;
            align-items: center;
            .language-dropdown {
                margin-right:2vw;
                position: relative;
                .selected-option {
                    img {
                        width:10vw;
                        display: block;
                        
                    }
                }
                .list {
                    padding:2vw;
                    width:100%;
                    box-sizing:border-box;
                    display: flex;
                    gap: 2vh;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    background:rgba($color: #000000, $alpha: 0.6);
                    img {
                        display:block;
                    }
                }
            }
        }
        .center {
            flex: 1;
        }
    }
    .body {
        overflow-y: scroll;
        position: relative;
        overflow-x:hidden;
        flex: 1;
        .winner-top {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            min-height: 70vh;
            background-size: auto 100%;
            background-position: top center;
            padding-top:5vh;
            background-repeat: no-repeat;

            position: relative;
            #confettiCanvas {
                position: absolute;
                left: 0;
                pointer-events: none;
                top: 0;
                width: 100%;
                height: 100%;
            }
            .winner-text {
                max-width: 80vw;

                margin-top: 5vh;
                text-align: center;
                font-size: 1.2rem;
                line-height: 2rem;
            }
            .winner-subtext {
              position: relative;
              z-index: 999;
              padding-top:3vh;
            .timestamp {
            
                left:0;
                

                z-index: 99;
                width:100%;
                text-align: center;
                vertical-align: middle;
                .date {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size:1.2rem;
                    text-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 1.0);
                    b {
                        font-size:1.5rem;
                        font-weight: normal;
                    }
                    span {
                        font-size:0.8rem;
                        position: relative;
                        top:-3px;
                        
                    }
                }
                .time {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size:1.2rem;
                    text-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 1.0);
                    .meridiem {
                        font-size:1.1rem;
                        font-weight: normal;
                        text-transform: lowercase;
                        padding:0 1vw;
                    }
                    .timezone {
                        font-size:0.8rem;
                        position: relative;
                        text-transform: lowercase;
                        top:-3px;
                    }
                }
                
            }
        }

            
        }
        .results-content-wrapper{
            position: relative;
            //top:-10vh;
        }
        .content {
            padding: 20px;
            padding-top:0;
            box-sizing: border-box;
          
            z-index: 999;
            position: relative;

            .content-header {
                font-size: 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 2vh;
                aspect-ratio: 65/14;
                text-align: center;
                background-size:100% 100%;
              

                .left {
                    flex: 1;
                    height: 1px;
                  
                }
                .text {
                    padding: 0px 20px;
                    font-size:2.5rem;
                    letter-spacing: 2px;
                }
                .right {
                    flex: 1;
                    height: 1px;
                    
                }
            }

            .list-item {
                display: flex;
                border-radius: 5px;
                background-size: 100% 100%;
                margin-bottom:5px;
                &:last-child {
                    margin-bottom: 0;
                }
                padding:10px 10px;
                .left {
                    width: 15vw;
                    // .ball-color-container {
                    //     display: flex;
                    //     justify-content: center;
                    //     align-items: center;
                    //     .ball-color {
                    //         aspect-ratio: 1/1;
                    //         width: 100%;
                    //         //background: temp-url('player-ball-frame.png');
                    //         background-color: black;
                    //         background-size: 100% 100%;
                    //         transform: rotate(0);
                    //     }
                    // }
                    .avatar-wrap {
                   
                        width:20vw;
                        height:20vw;
                        
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .avatar {
                            position: relative;
                            width:20vw;
                            height:20vw;
                            background-size: 400% 400%;
                            
    
                            box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 1.0);
                           
                            .avatar-frame {
                                position: absolute;
                                width:100%;
                                height:100%;
                                background-size:100% 100%;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }
                .right {
                    display: flex;
                    width:100%;
                    align-items: center;

                    padding-left: 10vw;
                   .detail {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    flex:1;
                    font-size: 1.5rem;
                 
                    small {
                        position: relative;
                        top:-5px;
                        font-size: 1rem;
                    }
                   }
                   .place {
                    display: flex;
                    font-size:2.5rem;
                    span {
                        position:relative;
                        font-size:1.0rem;
                        top:10px;
                    }
                   }
                }
            }
        }

        .scorecard-wrap {
            .scorecard-table {
                display: table;
                border:1px solid #fff;
                width: 100%;
                .table-header{
                    display: table-row-group;
                    vertical-align: center;
                    text-align: center;
                    .header-cell {
                       
                    }
                    .cell{
                        display: table-cell;
                       vertical-align: center;
                       text-align: center;
                        width:5.5%;
                        border-right:1px solid white;
                       
                    }
                }
                .row {
                    display: table-row;
                    .cell {
                        display: table-cell;
                        width:5.5%;
                        padding-top:10px;
                        padding-bottom:10px;
                       text-align: center;
                        vertical-align: center;
                        border-top:1px solid #fff;
                        border-right:1px solid white;
                    }
                }
            }
        }

        


        

        .performance-wrap {
            margin:10vw;
            margin-top:5vh;
            
            .title {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size:2rem;
            }

            .performance-dropdown {
                margin-top:2vh;
                position: relative;
                .selected-option {
                    display: flex;
                    background:#211b1b;
                    align-items: center;
                   
                    padding:10px 20px;
                    width:100%;
                
                    box-sizing: border-box;
                    .display {
                        .icon {

                        }
                        .text {

                        }
                    }
                    .close {
                        flex:1;
                        display: flex;
                        
                        justify-content: flex-end;
                        height:5vh;
                        .icon {
                            transition: all 0.2s ease-in-out;
                            aspect-ratio: 1/1;
                          
                            background-size:100% 100%;
                        }
                    }
                }
                .list {
                    display: none;
                    position: absolute;
                    background:#3d3232;
                    box-shadow: 0px 0px 10px rgba(#000, 0.5);
                    width: 100%;
                    z-index: 999;
                    left:0;
                    .item {
                        padding: 20px;
                        border-bottom:3px solid #342b2b;
                        .icon {

                        }
                        .text {

                        }
                    }
                }
            }

            .performance-chart {
               border:5px solid #271f1f;
                position: relative;
                border-top:0;
              width:100%;
               height:20vh;
              
               box-sizing: border-box;
               
                .recharts-surface {
                    padding:0;
                   
                    box-sizing: border-box;
                }
            }
        }

       
    }
}


.hole-in-one-wrapper {
    padding-top:5vh;
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size:2rem;
    }
    .no-hole-in-ones {
        text-align: center;
        margin-bottom:5vh;
    }
    $border: 2px solid #332929;
    .hole-table {
        display: table;
        background:#514141;
        box-sizing: border-box;
        border-top: $border;
        border-bottom: $border;
        margin-bottom:5vh;
        width:100%;
        margin-top:5vh;
        .hole-row {
            display: table-row;
            &:last-child {
                .hole-number {
                    border-bottom:0;
                } 
                .hole-players {
                    border-bottom:0;
                }
            }
            .hole-number {
                display: table-cell;
                text-align: center;
                vertical-align: middle;
                align-items: center;
                padding:5vw;
                width:20vw;
                border-bottom: 2px solid #443737;
                border-right:2px solid #443737;
            }
            .hole-players {
                display: table-cell;
                padding:3vh 3vh;
                border-bottom: 2px solid #443737;;
             
                .hole-player {
                    vertical-align: middle;
                   &:first-child {
                    margin-top:0;
                   }
                    margin-top:2vh;
                    // .hole-player-ball {
                    //     display: inline-block;
                    //     width:5vw;
                    //     aspect-ratio: 1/1;
                    //     vertical-align: middle;
                    //     transform: rotate(-45deg);
         
                    //     background-size:100%;
                    // }
                    .avatar-wrap {
                   
                       
                        display: inline-block;
                        justify-content: center;
                        align-items: center;
                        vertical-align: middle;
                        width:10vw;
                        height:10vw;
                        .avatar {
                            position: relative;
                            width:10vw;
                            height:10vw;
                            background-size: 400% 400%;
    
                            box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 1.0);
                        
                            .avatar-frame {
                                position: absolute;
                                width:100%;
                                height:100%;
                                background-size:100% 100%;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                    .player-name {
                        vertical-align: middle;
                        margin-left:3vw;
                        display: inline-block;
                    
                    }
                }
            }
        }
    }
    
}