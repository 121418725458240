.camera-feed-container {
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
 
    .cameraWrap {
        width:100%;
        height:100%;
        position: absolute;
        transform: scaleX(-1);
    }
    video {
        width: 100%;
        position: absolute;
        height: 100%;
        object-fit:cover;
        transform: scaleX(-1);
    }
    
}