@keyframes bounceIn {
    0% {
        opacity: 0;
        transform: scale(.3);
    }
    50% {
        opacity: 1;
        transform: scale(1.05);
    }
    70% {
        transform: scale(.9);
    }
    100% {
        transform: scale(1);
    }
}

.share-modal-wrap {
    position: fixed;
    left:0;
    top:0;
    width:100%;
    height:100svh;
    background:rgba(#000, 0.6);
    display:flex;
 
    justify-content: center;
    align-items: center;
    .share-modal {
        max-width:80vw;
        width:100vw;
        box-sizing: border-box;
        padding:5vw;
        display: flex;
        max-height:80svh;
        animation: bounceIn 0.5s;
        flex-direction: column;
        align-items: center;
       flex-direction: column;
        background:#000;
        color:#fff;
        .qr-wrap {
           height:20vh;
           svg {
                width:100%;
                height:100%;
           }
        }
        .share-link {
            display: flex;
            box-sizing: border-box;
            max-width: 100%;
            justify-content: center;
            align-items: center;
            margin-top:3vh;
            background:#333;
            
            padding-left:10px;
            .share-url {
                font-family:Arial, Helvetica, sans-serif;
                background:#333;
                overflow-x:hidden;
       
                span {
                    flex:1;
                    line-break:loose;
                    display: block;
                    width:1000px;
                }
            }
            .copy-button {
                
                margin-left:5vw;
                display: flex;
              
                background:#000;
                padding-left:2vw;
                span {
                    position: absolute;
                
                    right:15vw;
                    margin-top:-2vh;
                }
                svg {
                    display: inline-block;
                    aspect-ratio: 1/1;
                    width:10vw;
                    fill: #fff;
                }
            }
        }
        .big-share {
            margin-top:3vh;
            position: relative;
            span {
                position: absolute;
                left:0;
                top:0;
                width:100%;
                height: 100%;
                display: flex;
                pointer-events: none;
                align-items: center;
                justify-content: center;
                font-size: 7vw;
            }
            img {
                width:100%;
            }
        }

        .close {
            margin-top:3vh;
        }
    }
}

.share_debug {
    position: fixed;
    left:0;
    top:0;
    background:#000;
    font-size:8px;
    color:#fff;
    padding:10px;
    min-height:50vh;
    width:100vw;
    box-sizing: border-box;
    textarea {
        width:100%;
        font-family: Arial, Helvetica, sans-serif;
        box-sizing: border-box;
        min-height:40vh;
    }

}