// scss function to return a url
@function temp-url($path) {
    @return url('../../../../public/customers/castle-golf/images/gameplay/' + #{$path});
}

.game-page {
    height: 100svh;
    // if mobile set height to 100%
    display: flex;
    flex-direction: column;
    //background-image: url("../../../../public/customers/castle-golf/images/welcome-background.jpg");
    background-size: cover;

    .rounds-container {
        background: #000;
        height: 10%;
        display: flex;
        overflow: hidden;
        //background-image: temp-url("round-background.jpg");
        background-size: 100% 100%;
        border-bottom: 3px solid #54afa4;
        display: flex;
        .left-cap {
            width: 7%;
            //background-image: temp-url("round-left-cap.png");
            background-size: 100% 100%;
        }
        .right-cap {
            width: 7%;
            //background-image: temp-url("round-right-cap.png");
            background-size: 100% 100%;
        }
        .center {
            display: flex;
            flex: 1;
            align-items: center;
            width: 100%;
            max-width: calc(100% - 14%);
            .hole-label {
                padding-left: 10px;

                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                justify-content: center;
                padding-left: 20px;
                font-weight: bold;
                border-right: 1px solid #426e4d;
                color: #fff;
                text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
                box-shadow: 0px 0px 10px #000 !important;
                padding-right: 20px;
                span {
                    position: absolute;
                    bottom: 5px;
                    color: #fff;
                    font-weight: 100;
                    font-size: 0.8rem;
                }
            }
            .hole-items {
                overflow-x: scroll;
                
                
                white-space: nowrap;
                z-index: 999;
                position: relative;
                overflow-y: hidden;
                display: flex;
  
                height: 100%;
                align-items: center;
                padding-right: 10px;
                // hide scrollbar
                &::-webkit-scrollbar {
                    display: none !important;
                }
                flex: 1;
                .hole-item {
                    position: relative;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
                    font-size: 2rem;
                    position: relative;
                    height: 6vh;
                    width: 6vh;
                    flex: 1;
                    aspect-ratio: 1/1;
                    //background: temp-url("round-button.png");
                    background-size: 100% 100%;
                    margin-right: 20px;
                    span {
                        position: relative;
                        top: -2px;
                    }
                    .par {
                        position: absolute;
                        font-size: 0.8rem;
                        bottom: -5px;
                        b {
                            font-size: 1rem;
                        }
                    }
                    &.active-hole {
                        z-index: 120;
                        color: #fff !important;
                    }
                    &:first-child {
                        margin-left: 20px;
                    }
                    &:last-child {
                    }
                    .hole-status {
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        background-size: 17px 17px;
                        background-position: center center;
                        background-repeat: no-repeat;
                        top: -5px;
                        font-size: 0.8rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        right: -5px;
                        border-radius: 20px;
                    }
                }
            }
        }
    }
    .game-body {
        flex: 1;
        display: flex;
        box-sizing: border-box;
        overscroll-behavior: none;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none !important;
        }

        .player-container {
            flex: 1;
            flex-direction: column;

            box-sizing: border-box;
            display: flex;
            .player {
                box-sizing: border-box;
                flex: 1;
                display: flex;
                position: relative;
                align-items: center;
                padding: 20px 0;

                //background-image: temp-url('player-background.jpg');
                background-size: cover;
                opacity: 0.4;


                .avatar-wrap {
                   
                    padding-left:20px;
                    padding-right:20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .avatar {
                        position: relative;
                        width:20vw;
                        height:20vw;
                        background-size: 400% 400%;
                       

                        box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 1.0);
                   
                        .avatar-frame {
                            position: absolute;
                            width:100%;
                            height:100%;
                            background-size:100% 100%;
                            background-repeat: no-repeat;
                        }
                    }
                }


                .ball-color-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-size:100% 100%;

                    .ball-profile-container {
                        margin-left: 20px;
                        margin-right: 8vw;
                        aspect-ratio: 1/1;
                        height: 5vh;
                        position: relative;
                        background-size:100% 100%;
                        overflow: hidden;
                    }

                    .ball-color {
                        margin-left: 20px;
                        margin-right: 8vw;
                        aspect-ratio: 1/1;
                        height: 5vh;
                        position: relative;
                        //background: temp-url('player-ball-frame.png');
                      
                        background-size: 100% 100%;
                    }
                }
                .player-details {
                    flex: 1;

                    color: black;
                    .name {
                        font-size: 2rem;
                    }
                }
                .player-round-score {
                    width: 15vw;
                    display: flex;
                    justify-content: center;

                    .round-score {
                        aspect-ratio: 1/1;
                        background-size: 100% 100%;
                        height: 7vh;
                        margin-right: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 3rem;
                        color: #fff;
                        text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
                        span {
                            position: relative;
                            top: 0px;
                            left: 1px;
                        }
                    }
                }
            }
            .active-player {
                box-shadow: 0px 0px 50px #000;
                z-index: 10;
                opacity: 1;
                text-shadow: -1px 1px 1px #000c4e;
                &.onfire {
                    .fire {
                        visibility: visible;
                    }
                }
            }
        }
    }
    .game-details {
        background: rgba($color: #31251c, $alpha: 0.8);
        color: #f2fdb3;
        display: flex;
        justify-content: center;
        padding-top: 0.5vh;
        border-top: 2px solid #241b14;
        align-items: center;
    }
    .footer {
        background: #b8a674;
        display: flex;

        background-color: #31251c;
        border-top: 3px solid #31251c;
        .footer-content {
            flex: 1;
            display: flex;

            .numbers-wrap {
                flex: 1;
                display: flex;

                .numbers {
                    flex: 1;
                    display: table;

                    .row {
                        display: table-row;

                        .score {
                            outline: none;
                            border: none;
                            width: 33.3%;
                            font-size: 3rem;
                            padding: 5px;
                            color: #4a330d;

                            font-weight: bold;
                            text-shadow: 0px 0px 15px #edd488;
                            display: table-cell;
                            //background-image: temp-url('score-button.jpg');
                            background-size: 100% 100%;

                            &:hover {
                                //opacity: 0.6;
                            }
                        }
                    }
                }
            }
            .options-group {
                width: 35%;
                display: flex;
                flex-direction: column;
            }
            .clear-button {
                flex: 1;
                //background-image: temp-url('next-hole-button.jpg');
                background-size: 100% 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 20px;
                box-sizing: border-box;
                color: #feff8b;
                outline: none;
                background-color: #1d7303;
                border: none;

                &.darken {
                    opacity: 0.5;
                }

                span {
                    font-weight: bold;
                    text-shadow: 0px 0px 10px #4a330d;
                }
                &:hover {
                    //opacity: 0.6;
                }
            }
            .smaller-options {
                display: flex;
                .option-indi {
                    flex: 1;
                    aspect-ratio: 1/1;
                    width: 50%;
                    background-size: 100% 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    $iconAlpha: 0.5;
                    svg {
                        width:50%;
                        fill: rgba($color: #fff, $alpha: $iconAlpha);
                        height:50%;
                    }
                    g {
                        fill: rgba($color: #fff, $alpha: $iconAlpha);
                    }
                }
            }
        }
    }
}