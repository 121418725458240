.unavailable-wrap {
    position: fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    display: flex;
    background:#efefef;
    font-family: Arial, Helvetica, sans-serif;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .unavailable-content {
        text-align: center;
        padding:5vw;
        box-sizing: border-box;
    }
}