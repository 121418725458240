.snap-picture-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: #000;
    display: flex;
    color: #fff;
    touch-action: none;
    justify-content: center;
    align-items: center;
    $buttons-shadow: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.4));
    .back {
        position: fixed;
        z-index: 99;
    }
    .camera-debug-info {
        position: absolute;
        top: 0;
        display: none;
        left: 0;
        width:100%;
        color: #ffffff;
        font-size: 1rem;
        font-family: Arial, Helvetica, sans-serif;
        z-index: 100;
        padding: 10px;
        background-color: rgba($color: #000000, $alpha: 0.7);
    }
}





   