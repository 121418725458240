.camera-save-snap-container {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 50;
    width: 100%;
    box-sizing: border-box;
    pointer-events: none;
    background: rgba($color: #000000, $alpha: 0.7);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

    padding: 5vw;
    .inside-container {
        position: relative;
        .snapped-save-text {
            text-align: center;
            flex: 1;
            border-radius: 30px;
            box-sizing: border-box;
            justify-content: center;
            padding: 5vw;
            background-color: #336cf1;
            align-items: center;
            font-size: 1.5rem;
            width: 100%;
            &:focus {
                animation: pushIn 0.2s ease-in-out;
            }
        }
        @keyframes pushIn {
            0% {
                transform: scale(1);
            }
            100% {
                transform: scale(0.5);
            }
        }
        .save-to-card-button {
            position: absolute;
            width:100%;
            left:0;
            display: flex;
            pointer-events:all;
            justify-content: center;
            letter-spacing: 0.2rem;
            align-items: center;
            text-align: center;
            font-size:1.1rem;
            text-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 1.0);
            top:-6vh;
            input {
                margin-right:10px;
            }
        }
    }
}
