.camera-snap-result-viewer{
    position: absolute;
    left:0;
    z-index: 40;
    top:0;
    width:100%;

    height:100%;

    overflow: hidden;
    background:#000;
 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size:100% 100%;
    img {
        position: absolute;
        left:0;
        width:0;
        display: block;
        max-width:100%;
        width:100%;
        height:100%;
      
    }
}