.settings-override {
    
.settings-page {
    display: flex;
    flex:1;
    flex-direction: column;
    .settings-body {
        flex:1;
        .admin-mode-wrap {
            .title {
                margin-bottom:20px;
            }
            .admin-mode-hidden {
                display: none;
            }
        }
    }
    .settings-footer {
        height:10vh;
        padding-top:20px;
        .back-button {
            position: relative;
            span {
                position: absolute;
                left:0;
                top:0;
                pointer-events: none;
                width:100%;
                height:100%;
                display: flex;
                font-size:2rem;
                justify-content: center;
                align-items: center;
            }
        img {
            width:100%;
        }
    }
    }
}
}