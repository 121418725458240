.camera-toolbar {
    position:fixed;
    z-index: 40;
    left:0;
    top:3vh;
    padding:5vw;
    width:100%;
    box-sizing: border-box;

    .inside-container {
        display: flex;
        width:100%;
        justify-content: center;
        align-items: center;
        .tooltip-left {
            flex:1;
          
            .close-button {
                display: flex;
          
                align-items: center;
                svg {
                    width:10vw;
                    border-radius: 100vw;
                    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
                    path {
                        fill:#fff;
                        //add shadow
                        
                    }
                    height:10vw;
                }
            }
        }
        .tooltip-right {
            flex:1;
      
            .done-button {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                svg {
                    width:10vw;
                    border-radius: 100vw;
                    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
                  
                    path {
                        fill:#336cf1;
                        stroke: #fff;
                        //add shadow
                        
                    }
                    height:10vw;
                }
            }
        }
    }
}