.general-loading-template {
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100vh;
    display:flex;
    flex-direction: column;
    align-items:center;
    background-color: #000;
    background-size:cover;
    background-position: center center;
    box-sizing: border-box;
    padding:20px;
    color:#fff;
    .logo {
        width:100%;
        text-align: center;
        font-weight: bold;
        height:100%;
        max-height:100px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 2rem;
    }
    .body {
        text-align: center;
        width:100%;
        flex:1;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content: center;
        font-size: 1.5rem;
    }
    .footer {
    }
}