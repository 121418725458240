.tie-content {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .way {
        font-size:2rem;
    }
    .title {
        line-height: 6rem;
        font-size:7.5rem;
    }
    .winners {
        margin-top:5vh;
       font-size:1.4rem;
        .winner {
            margin:0px 10px;
            display: inline-block;
        }
    }
}