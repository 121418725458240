.camera-preflight-wrapper {
    background:#000;
    flex:1;
    height:100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
    box-sizing: border-box;
    .preflight-enable-wrap {
        padding:5vw;
    .large-text {
        font-size: 2.1rem;
        
        text-align: center;
        margin-bottom: 1vh;
    }
    .error-text {
        margin-bottom:1vh;
        text-align: center;
        color:rgb(255, 41, 41);
    }
    .enable-camera-button {
        background:#336cf1;
        padding:3vh 8vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:1.5rem;
        box-sizing: border-box;
        font-weight: normal;
        border-radius: 10px;
   
    }
    .back-button {
        margin-top:1vh;
        padding:3vh 8vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background:#333;
        border-radius: 10px;
        font-size:1.5rem;
    }
}
}