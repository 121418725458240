.app {
  margin:0 auto;

  height:100%;
    max-height:-webkit-fill-available;

  background:#fff;
  h1 {
    margin:0;
    padding: 0;
  }
}

.debug-wrap {
  position: fixed;
  display:none;
  left:0;
  top:70%;
  pointer-events: none;
  background-color: rgba($color: #000000, $alpha: 0.7);
  padding:20px;
  text-align: center;
  width:100%;
  box-sizing: border-box;
  z-index: 9999;
  color:#fff;
}
.global-message-wrap {
  position: fixed;

  left:2vw;
  top:-50vw;
  pointer-events: none;
  background:#00192d;
  padding:20px 10px;
  transition: all 0.5s ease-in-out;
  text-align: center;
  width:96vw;
  box-sizing: border-box;
  z-index: 9999;
  background-size:100% 100%;
  text-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 1.0);
  border:2px solid black;
  box-shadow: 0px 0px 30px rgba($color: #000000, $alpha: 1.0);

  
  color:#fff;
}