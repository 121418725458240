.confirmation-popup-modal-wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 888;

    background: rgba(0, 0, 0, 0.8);

    .confirmation-popup-modal {
        display: flex;
        flex-direction: column;
        width: 80vw;
        border-radius: 3vw;
        box-sizing: border-box;
        animation: popup 0.3s ease-in-out forwards;
        .confirmation-modal-content {
            flex: 1;
            border-top-left-radius: 3vw;
            border-top-right-radius: 3vw;
            padding: 5vw;
            text-align: center;
            background: #333;
        }
        .confirmation-modal-buttons {
            display: flex;
            .confirmation-modal-button {
                flex: 1;
                button {
                    display: block;
                    outline: none;
                    border: 0;
                    box-sizing: border-box;
                    color: #fff;
                    background: #336cf1;
                    padding: 2.5vh 0;
                    font-size: 1.2rem;
                    width: 100%;
                    border-bottom-left-radius: 3vw;
                    border-bottom-right-radius: 3vw;
                }
            }
            .both-buttons {
                &:nth-child(1) {
                    button {
                        border-bottom-right-radius: 0;
                    }
                }
                &:nth-child(2) {
                    button {
                        border-bottom-left-radius: 0;
                    }
                }
                &.cancel-button {
                    button {
                        background: #e24242;
                    }
                }
            }
        }
    }
    @keyframes popup {
        0% {
            opacity: 0;
            transform: scale(0.5);
        }
        50% {
            opacity: 0.5;
            transform: scale(1.3);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
}
