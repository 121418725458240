.welcome-page {
    display: flex;
    justify-content: center;
    align-items: center;
    .welcome-buttons {
        max-width:80vw;
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        flex:1;
        .button {
            margin-bottom:1vh;
            position: relative;

            span {
                position: absolute;
                left:0;
                top:0;
                width:100%;
                height:100%;
                text-shadow: 0px 0px 10px #000;
                font-size:1.8rem;
                letter-spacing: 0.1rem;
                text-align: center;
                color:#feff8b;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &.continue-game-button {

            }
            &.create-game-button {

            }
            &.rules-button {

            }
            
        }
        img { 
            width:100%;
            max-height: 10vh;
        }
        
        
    }
   
}