.share-game-wrap {
    display: flex;

    .icon {
   
        margin-right:5vw;
        display: flex;
        align-items: center;
        justify-content: center;

        box-sizing: border-box;
        span {
            margin-right:2vw;
        }
        &:last-child {
            margin-right:0;
        }
        svg {
            display: block;
            width: 7vw;
            height:7vw;
            aspect-ratio: 1/1;
            fill: #fff;
        }
    }
    .share {
        padding:10px;
        background:rgba(#000, 0.1);
    }
}