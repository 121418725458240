.snap-gallery-wrap {
  
    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size:2rem;
    }
    .no-pictures {
        text-align: center;
    }
    .snap-gallery {
        padding:5vh 5vw;

        box-sizing: border-box;
       
      
        .snap-picture-indi {
          
            
            width:100%;
            margin-bottom:5vh;
            img {
                display: block;
                width:100%;
            }

            &.loaded {
                .spinner1 {
                    display: none !important;
                }
            }
       
            position: relative;
            .spinner1 {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
              background:rgba($color: #000000, $alpha: 0.6);
                width:100%;
                height:100%;
                svg {
                    width:20vw;
                    height:20vw;
                    fill: #fff;
                    animation: spinAndPulse 3s linear infinite;
                }
            }
        
        }

        .snap-picture-wrapper {
            position: relative;
            .spinner {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width:100%;
                height:100%;
                svg {
                    width:20vw;
                    height:20vw;
                    fill: #fff;
                    animation: spinAndPulse 3s linear infinite;
                }
            }

          

    }
    }
}

