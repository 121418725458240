.existing-game-modal {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    background: rgba($color: #000000, $alpha: 0.8);
    box-sizing: border-box;
    
    position: fixed;
    left:0;
    top:0;
    z-index: 99;
    width:100%;
    height:100%;

    .body {
        padding:20px;
    h1 {
        color: #feff8b;
        letter-spacing: 0.1rem;
        font-size:2.5rem;
    }
    p {
        color: #fff;
        font-size: 1.5rem;
        margin:20px 0;
        margin-bottom:40px;
    }
    .buttons {
        display:flex;
        width:100%;
        box-sizing: border-box;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .button {
            &:first-child {
               
            }
            width:60%;
            display: flex;
            flex-direction: column;
            text-shadow: 0px 0px 10px #000;
            align-items: center;
            justify-content: center;
            padding:0px 20px;
           
            margin-bottom:20px;
            font-size:1.8rem;
            border-radius: 10px;
            background:rgba($color: #000000, $alpha: 0.7);
            background-size:100% 100%;
            aspect-ratio: 50/21;
            &.no {

            }
            &.yes {
                
            }
        }
    }
}
}