.profile-screen-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    color: #fff;
    display: flex;
    flex-direction: column;
    .header {
       
        
        background-size:cover;
        background-position: center bottom;
        transition: all 0.2s ease-in-out;
        position: relative;
        padding-bottom:5vh;
        ._top-bar {
            display: flex;
            padding-top:5vh;
            box-sizing: border-box;
            ._left {
                flex:1;
                .back {
                   margin-left:5vw;
                    svg {
                        width:7vw;
                        height:7vw;
                        fill: #fff;
                        filter: drop-shadow(0px 0px 10px rgb(0 0 0 / 0.4));
                    }
                }
            }
            ._middle {
                width:30vw;
                .logo {
                    width:100%;
                    svg {
                        filter: drop-shadow(0px 0px 10px rgb(0 0 0 / 0.4));
                    }
                    rect {
                        filter: drop-shadow(0px 0px 10px rgb(0 0 0 / 0.4));
                    }
                }
            }
            ._right {
                flex:1;
                
                align-items: flex-end;
                .logout-button {
                    margin-right:5vw;
                    text-align: right;
                }
            }
        }
        .trans-bottom {
            background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,1));
            bottom:0;
            width:100%;
            height:5vh;
            position: absolute;
        }

        .profile-detail-structure-wrap {
            height:10vh;
            z-index: 999;
            
            position: relative;
            .profile-details-wrap {
                position: absolute;
                left:0;
                padding:0 5vw;
                top:3vh;
                box-sizing: border-box;
                width:100%;
                
                display: flex;
                .profile-image-wrap {
                    width:30vw;
                    padding:1vw;
                    height:30vw;
                    background:#000;
                    display: flex;
                    .profile-image {
                        flex:1;
                        display: flex;
                        overflow: hidden;
                        background:#333;
                        position: relative;
                        input {
                            display: none;

                        }
                        .upload-picture-button {
                            position: absolute;
                            top:0;
                            right:0;
                            height:100%;
                            width:100%;
                            text-align: center;
                            display: flex;
                            font-size:0.9rem;
                            justify-content: center;
                            align-items: center;
                            flex:1;
                            
                        }
                    }
                }
            }
            .profile-description-wrap {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left:5vw;
                text-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 1.0);
                flex:1;
                .username {
                    color:#336cf1;
                    font-size:2.3rem;
                    line-height: 2.2rem;
                }
                .joined-at {
                    color:#999;
                    font-size:1rem;
                    font-style: italic;
                }
                .rewards-points {
                    display: flex;
                }
            }
        }
    }

    .spacer {
        height:6vh;
    }
    .reward-id-button-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        padding:0 5vw;
        background:#000;
 
        .reward-id-button {
            background: #336cf1;
            color:#fff;
            flex:1;
            text-align: center;
            padding:1vw 2vw;
            text-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 1.0);
            flex: 1;
            padding:5vw 5vw;
            text-align: center;
            background:#336cf1;
            border-radius: 10px;
        }
    }

    .profile-navi-wrap {
        display: flex;
        border: 2px solid #333;
        border-left:0;
        border-right:0;
        justify-content: center;
        position: relative;
        z-index: 999;
        align-items: center;
        margin-top:3vh;
        margin-bottom:1vh;
        .profile-navi-main {
            display: flex;
            flex:1;
            justify-content: center;
            align-items: center;
            .profile-navi-item {
                display: flex;
                flex: 1;
                padding:2vw;
                justify-content: center;
                align-items: center;
            }
            .active-profile-navi {
                background:#333;
            }
        }
    }

    .content-main-container {
      
        flex:1;
        width:100%;
        height:100%;
        position: relative;
        .empty-top-space {
           height:2vh;
           position: relative;
           z-index: 99;
        }
   
        .top-trans {
            height:5vh;
            width:100%;
            position: absolute;
            z-index: 99;
            left:0;
            top:-1vh;
            pointer-events: none;
      
            background-image: linear-gradient(to top, rgba(255,0,0,0), rgba(0,0,0,1));
            bottom:0;
            width:100%;
            
            height:2vh;
            position: absolute;
        }

        
        .large-loader {
            position: absolute;
            left:0;
            display: flex;
            background-color: rgba($color: #000000, $alpha: 0.8);
            z-index: 99;
            justify-content: center;
            width:100%;
            height:110%;
            top:-10%;
         
            .spinner {
                position: relative;
                top:10vh;
                svg {
                    $size : 10vw;
                    width:$size;
                    height:$size;
                    fill: white;
                    scale:1;
                    animation: spinner 2s linear infinite;
                }
            }
            @keyframes spinner {
                0% {
                    transform: rotate(0deg);
                }
                50% {
                    scale:1.2;
                }
                100% {
                    scale:1;
                    transform: rotate(360deg);
                }

            }
        }

        .content-main {
            position: absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            box-sizing: border-box;
            overflow-y: scroll;

            

            .refresh-button {
            
                justify-content: center;
                align-items: center;
                display: flex;
                color:#999;
                margin-bottom:2vh;
            }
            .no-rewards-found {
                justify-content: center;
                align-items: center;
                display: flex;
                color:#fff;
                margin-bottom:2vh;
            }
   
            .rewards-container-wrap {
                padding:0 5vw;
                .reward-indi {
                    border-radius: 10px;
            
                    margin-bottom:5vh;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                  
                    .reward-trans {
                        background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,1));
                        height:5vh;
                        bottom:-0.1vh;
                        display: none;
                        left:0;
                        width:100%;
                        position: absolute;
                    }
                    .reward-top {
                        display: flex;
                        
                        height:15vh;
                        background-size:cover;
                        position: relative;
                        .darker-overlay {
                            position: absolute;
                            left:0;
                            top:0;
                            width:100%;
                            height:100%;
                            background-color: rgba($color: #000000, $alpha: 0.2);
                        }
                        .reward-customer {
                            
                            justify-content: center;
                            
                            display: flex;
                            position: relative;
                            z-index: 2;
                            
                            padding-left:5vw;
                            flex-direction: column;
                            .customer-name {
                                font-size:2rem;
                            }
                            .joined-on {
                                font-size:0.8rem;
                            }
                        }
                        .reward-points-container {
                            display: flex;
                            flex-direction: column;
                            flex:1;
                            position: relative;
                            z-index: 2;
                            justify-content: center;
                           
                            align-items: flex-end;
                            padding-right:5vw;
                           .reward-points {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                .points {
                                    padding:1vw 5vw;
                                    font-size:1.5rem;
                                    background:rgba($color: #000000, $alpha: 0.4);
                                    margin-bottom:0.5vh;
                                }
                           }
                        }
                        
                    }
                    .reward-bottom {
                        padding-top:1vh;
                        display: flex;
                        justify-content: space-between;
                        .view-reward-history {
                            text-align: right;
                           color: #336cf1;
                           
                        }
                        .view-games {
                            text-align: left;
                            color: #336cf1;
                        
                        }
                    }
                }
            }
            

            .recent-games-container-wrap {
                padding:0 5vw;
                .game-indi {
                    display: flex;
                    background-size:100% 80%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    margin-bottom:3vh;

                    .indi-left {
                        flex:1;
                       
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding-left:3vw;
                        .left-top {
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            padding:2vh 0;
                            padding-bottom:0;
                            text-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 1.0);
                            .game-title {
                                font-size:1.5rem;
                            }
                            .game-date {
                                margin-top:5px;
                                font-size:0.8rem;
                            }
                        }
                        .left-bottom {
                            padding-top:1vh;
                            position: relative;
                            .game-button {
                           
                                display: inline-block;;
                                background: #336cf1;
                                font-size: 1.3rem;
                                padding:5px 10px;
                                border-radius: 10px;
                                text-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 1.0);
                            }
                        }
                    }
                    .indi-right {
                       
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        padding-right:2vw;
                        .avatars-wrap {
                            position: relative;
                      

                            height:100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding-right:1vw;
                         
                            .single-avatar-wrap {
                                width:8vw;
                                overflow: hidden;
                                border-radius: 1000px;
                                position: relative;
                                height:8vw;
                                border:2px solid #000;
                                background-color: green;
                                
                                margin-bottom:2px;
                                &:last-child {
                                    margin-bottom:0px;
                                }
                                
                                .single-avatar {
                                    position: absolute;
                                    left:0;
                                    top:0;
                                    width:100%;
                                    height:100%;
                                    background-size:400% 400%;
                                }
                              
                            }
                        }
                        .winning-avatar {
                            position: absolute;
                            left:0;
                            top:0;
                            width:100%;
                            height:100%;
                            background-size:400% 400%;
                        }
                        .winning-avatar-wrap {
                            flex:1;
                            height:15vh;
                            width:15vh;
                            display: flex;
                            position: relative;
                            .winning-frame {
                                position: absolute;
                                left:0;
                                top:0;
                                width:100%;
                                height:100%;
                                background-size:100%;
                            }
                        }
                    }
                }
            }
        }


    }
    
}

.profile-qr-scan-wrap {
    position: fixed;
    left:0;
    top:0;
    width: 100%;
    height:100%;
    background: rgba($color: #000000, $alpha: 0.9);
    z-index: 3000;
    display: flex;
    justify-content: center;
    align-items: center;
    .profile-qr-scan-content {
        transition: all 0.2s ease-in-out;
        animation: BounceIn 0.5s ease-in-out;
        .profile-qr-scan-box {
        box-sizing: border-box;
            padding:0 5vw;
            svg {
                box-sizing: border-box;
                background:#fff;
                padding:5vw;
                width:100%;
                
            }
        }
        .qr-scan-text {
            padding:5vw;
            text-align: center;
        }
        .qr-scan-close {
            .close-button {
                background: #336cf1;
                color:#fff;
                flex:1;
                text-align: center;
                padding:1vw 2vw;
                text-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 1.0);
                flex: 1;
                padding:5vw 5vw;
                text-align: center;
                background:#336cf1;
                border-radius: 10px;
            }
        }
    }
    @keyframes BounceIn {
        0% {
            transform: scale(0.1);
            opacity: 0;
        }
        60% {
            transform: scale(1.2);
            opacity: 1;
        }
        100% {
            transform: scale(1);
        }
    }
    
}