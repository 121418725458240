.camera-filter-viewer{
    position: fixed;
    left:0;
    z-index: 20;
    top:0;
    width:100%;
    pointer-events: none;

    height:100%;
    background-size:100% 100%;
}