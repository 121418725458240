.sign-in-register-screen-wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    color: #fff;
    display: flex;
    flex-direction: column;
    .header {
        height:100%;
        max-height:60%;
        background-size:cover;
        background-position: center bottom;
        transition: all 0.2s ease-in-out;
        position: relative;
        padding-bottom:5vh;
        ._top-bar {
            display: flex;
            padding-top:5vh;
            ._left {
                flex:1;
                .back {
                   margin-left:5vw;
                    svg {
                        width:7vw;
                        height:7vw;
                        fill: #fff;
                        filter: drop-shadow(0px 0px 10px rgb(0 0 0 / 0.4));
                    }
                }
            }
            ._middle {
                width:30vw;
                .logo {
                    width:100%;
                    svg {
                        filter: drop-shadow(0px 0px 10px rgb(0 0 0 / 0.4));
                    }
                    rect {
                        filter: drop-shadow(0px 0px 10px rgb(0 0 0 / 0.4));
                    }
                }
            }
            ._right {
                flex:1;
            }
        }
        .trans-bottom {
            background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,1));
            bottom:0;
            width:100%;
            height:5vh;
            position: absolute;
        }
    }
    .details {
        padding:5vw;
        
        text-align: center;
        h1 {
            font-weight: 100;
            font-size:1.5rem;
        }
        p {
            font-size:1.0rem;
        }
    }
    .buttons {
        flex:1;
        padding-bottom:10vh;
        display: flex;
        .buttons_wrap {
            width: 50%;
     
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding:0px 5vw;
            span {
                flex: 1;
                padding:5vw 5vw;
                text-align: center;
                border: 2px solid #336cf1;
                background:#222;
                border-radius: 10px;
                
            }
            &:first-child {
                span {
                    background:#336cf1;
                    color:#Fff;
                }
                
            }
        }
    }
}

.code-waiter-wrap {
    text-align: center;

    .hidden-tel {
        position: absolute;
        left:-2000px;
    }
    .code-waiter-buttons-wrap {
        width:100%;
        box-sizing: border-box;
        display: flex;
     
        justify-content: center;
        padding:2vh 0;
        padding-bottom:2vh;
        padding-top:5vh;
        align-items: center;
        .code-waiter-buttons {
            padding:0 10vw;
            display:flex;
            width:100%;
            gap: 5vw;
            justify-content: space-between;
            .code-waiter-button {
                aspect-ratio: 1/1;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size:3.2rem;
                width:100%;
                background:#333;
                border:2px solid #444;
                box-shadow:0px 0px 10px #000;
                border-radius: 10px;
                &:first-child {
                    background:#336cf1;
                    color:#fff;
                    border-color: #336cf1;
                }
            }

          
            
            .code-waiter-button-filled {
                background:#336cf1;
                border-color: #336cf1;
                color:#fff;
          
            }
        }
    }
    .code-waiter-description {
        padding:0 5vw;
    }
}

.sign-in-wrap {
    display: flex;
    height:100%;
    flex-direction: column;
    .top-content {
        display: flex;
        flex-direction: column;
        flex:1;
        height:100%;
    }
    h1 {
        text-align: center;
        font-weight: 100;
    }
    
    .sign-in-button-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom:3vh;
        box-sizing: border-box;
        .sign-in-button {
            margin:0 5vw;
            background:#336cf1;
            color:#fff;
            font-size:1.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            text-align: center;
            height:8vh;
            flex:1;
            .loading-spinner-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                .loading-spinner {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg {
                        width:5vh;
                        height:5vh;
                        fill: #fff;
                        animation: rotate 2s linear infinite;
                    }
                }
                .loading-text {
                    padding-left:2vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                @keyframes rotate {
                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }
}


.register-wrap {
    display: flex;

    flex-direction: column;
    height:100%;


    .register-top {
        display: flex;
        flex-direction: column;
        flex:1;
        height:100%;

        .register-welcome {
            padding:5vw;
            
            text-align: center;
            h1 {
                font-weight: 100;
                margin-top:0;
               
            }
            p {
                font-size:1.0rem;
            }
        }
    }

    .register-scrollable {
      
        flex:1;
        position: relative;
    
        .register-scrollable-inside{
            position: absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
           
            overflow-y: auto;
           
            h2 {
                color:#336cf1;
                text-align: center;
                font-weight: normal;  
                margin-top:0;
            }
            .ref-phone-entry {
                padding-bottom:1vh;
            }

            .refItem {
                padding: 1vh 5vw;
            }

            .validation-error {
                border-color:red !important;
            }

            .ref-details-input {
                position: relative;
                .checked-wrap {
                    position: absolute;
                    right:-3vw;
                    top:-3vw;
                    width:10vw;
                    height:10vw;
                    svg {
                        width:100%;
                        height:100%;
                        fill: rgb(255, 255, 255);
                        path {
                            stroke: #336cf1;
                            stroke-width: 0.5vw;
                        }
                    }
                }
                input {
                    width:100%;
                    padding:5vw;
                    height:8vh;
                    outline: none;
                    background: #333;
                    border:2px solid #336cf1;
                    border-radius: 10px;
                    text-align: center;
                    color: #fff;
                    font-size:1.6rem;
                    font-weight: 100;
                    box-sizing: border-box;
                }
            }

            .ref-legal-wrap {
                padding: 5vw;
                .checkboxes-wrap {
                    display: flex;
                    flex-direction: column;
                    .checkbox {
                        margin-bottom: 2vh;
                    
                        label {
                            display: flex;
                            padding-left: 5vw;
                    
                            input[type="checkbox"] {
                                scale: 2;
                                outline: none;
                                border: 0.7vw solid #336cf1;
                                margin-right: 5vw;
                                width: 4vw;
                                height: 4vw;
                                border-radius: 5px;
                                -moz-appearance: none;
                                -webkit-appearance: none;
                                -o-appearance: none;
                                position: relative;
                                background: #336cf1;
                                border-color: #244fb4;
                    
                                &:checked {
                                    background: #fff;
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        width: 1.8vw;
                                        height: 0.7vw;
                                        border: solid #336cf1;
                                        
                                        border-width: 0 0.3vw 0.3vw 0;
                                        top: 36%;
                                        left: 33%;
                                        transform: rotate(-45deg) translateY(-50%) scaleX(-1);
                                    }
                                }
                            }
                        }
                    
                }
                }
            }
            
        }
    }

    .register-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom:3vh;
        box-sizing: border-box;
        padding-top:2vh;

       

        .register-button {
            margin:0 5vw;
            // background:#2ad613;
            background: #336cf1;
            color:#fff;
            font-size:1.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            text-align: center;
            height:8vh;
            flex:1;
            .loading-spinner-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                .loading-spinner {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg {
                        width:5vh;
                        height:5vh;
                        fill: #fff;
                        animation: rotate 2s linear infinite;
                    }
                }
                .loading-text {
                    padding-left:2vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                @keyframes rotate {
                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }
        #disabledBtn {
            background: #333;
            color: #666;
        }
        
    }
}

.creating-account-modal {
    position: fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index: 9;
    background: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    .__content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .text {
            font-size:2.5rem;
         
        }
        .loading-spinner {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width:10vh;
                height:10vh;
                fill: #336cf1;
                animation: rotate 2s linear infinite;
            }
        }
    }
}


.logging-in-wrap {
    position: fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index: 9;
    background: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    .logging-in {
        display: flex;
     
        flex-direction: column;
        align-items: center;
       box-sizing: border-box;
       padding:0 5vw;
       width:100%;
       
      
        .text {
            font-size:2.5rem;
         
        }
        .loading-spinner {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width:10vh;
                height:10vh;
                fill: #336cf1;
                animation: rotate 2s linear infinite;
            }
        }
        .try-again-button {
            margin-top:5vh;
       
            box-sizing: border-box;
            background:#336cf1;
            color:#fff;
            font-size:1.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            text-align: center;
            width:100%;
            height:8vh;

        }
    }
}