.image-processing-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    .thumb-wrap {
        .thumb {
            border: 5px solid rgba($color: #000, $alpha: 0.6);
            width:10vw;
            height:10vw;
            border-radius: 20px;
            background-color:#000;
            background-size:100%;
            background-position: center center;
            scale: 1;
            animation: pulser 2s linear infinite;
        }

        @keyframes pulser {
            0% {
                scale: 1;
            }
            50% {
                scale: 1.1;
            }
            100% {
                scale: 1;
            }
        }
    }
    .title {
        margin-top:0.2vh;
        font-size:0.9rem;
        text-shadow: 0px 0px 15px #000;
        color:#ffd942;
    }
 
    @keyframes pulseOut {
        0% {
            scale: 1;
        }
        50% {
            scale: 1.5;
        }
        100% {
            scale: 0;
        }
    }
    @keyframes pulseIn {
        0% {
            scale: 0;
        }
        100% {
            scale: 1;
        }
    }
    &.in {
        animation: pulseIn 1s linear;
    }
    &.out {
        animation: pulseOut 1s linear;
    }
}